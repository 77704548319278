import request from "@/common/utils/request";
import Vue from "vue";

export function getUserList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/list",
    method: "post",
    data
  });
}

export function addUser(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/add",
    method: "post",
    data
  });
}

export function updateUser(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/update",
    method: "post",
    data
  });
}

export function deleteUser(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/delete",
    method: "post",
    data
  });
}

export function getAllUserList() {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/get-all-list",
    method: "post"
  });
}

export function getMyYYUserList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/getMyYYUserList",
    method: "post",
    data
  });
}
export function addYYUser(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/addYYUser",
    method: "post",
    data
  });
}
export function updateYYUser(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/updateYYUser",
    method: "post",
    data
  });
}
export function userRegistration(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/userRegistration",
    method: "post",
    data
  });
}
export function shenhe(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + "/user/updateStatus",
    method: "post",
    data
  });
}
