import request from '@/common/utils/request'
import Vue from "vue";

export function getAllRole() {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/get-role-list',
    method: 'post'
  })
}

export function getRoleList(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/list',
    method: 'post',
    data
  })
}

export function addRole(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/add',
    method: 'post',
    data
  })
}

export function updateRole(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/update',
    method: 'post',
    data
  })
}

export function deleteRole(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/delete',
    method: 'post',
    data
  })
}

export function getRoleResource(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/get-role-resource',
    method: 'post',
    data
  })
}

export function allotRoleResource(data) {
  return request({
    url: Vue.prototype.$adminApiUrl + '/role/allot-role-resource',
    method: 'post',
    data
  })
}
